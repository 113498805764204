import * as React from "react";

import BlankLayout from "../components/BlankLayout";
import Seo from "../components/Seo";

const NotFoundPage = () => (
  <BlankLayout>
    <Seo title="404: Not Found" />
    <span>404: Not Found</span>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </BlankLayout>
);

export default NotFoundPage;
