/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

// packages
import * as React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
// components
import Theme from "./Theme";
// styles
import "../css/layout.css";

const SiteWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const BlankLayout = ({ children }) => {
  return (
    <Theme>
      <SiteWrapper>
        <main>{children}</main>
      </SiteWrapper>
    </Theme>
  );
};

BlankLayout.propTypes = {
  children: PropTypes.node.isRequired
};

export default BlankLayout;
